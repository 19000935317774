const recipes = [
  {
    name: 'Crossaint',
    method: "Air Fryer",
    cookingTime: '22 minutes',
    cookingTemp: "155",
    targetTemp: null,
    instructions: 'Place on foil in Air Fryer'
  },
  {
    name: 'Meatballs',
    method: "Air Fryer",
    cookingTime: '16 minutes',
    cookingTemp: "170",
    targetTemp: "72c",
    instructions: 'Place on foil in Air Fryer'
  },
  {
    name: 'Pitta',
    method: "Air Fryer",
    cookingTime: '6 minutes',
    cookingTemp: "150",
    targetTemp: null,
    instructions: null
  },
  {
    name: 'Scampi',
    method: "Air Fryer",
    cookingTime: '18 minutes',
    cookingTemp: "180",
    targetTemp: null,
    instructions: "Turn half way through"
  },
  {
    name: 'Fish Fingers',
    method: "Air Fryer",
    cookingTime: '20 minutes',
    cookingTemp: "180",
    targetTemp: null,
    instructions: "Turn half way through"
  },
  ];

  export default recipes;